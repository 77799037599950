function showModal(video) {
    console.log('showModal called:', video);
    const modal = document.getElementById('video-modal');
    const descriptionContainer = document.getElementById('video-description');

    if (!modal || !descriptionContainer) return;

    // Clear existing content
    descriptionContainer.innerHTML = '';

    // Open the modal
    modal.style.display = 'block';

    // Add video views and published date
    var publishedAt = new Date(video.publishedDate);
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var formattedDate = publishedAt.toLocaleDateString('en-US', options);

    const detailsContainer = document.createElement('div');
    detailsContainer.style.display = 'flex';
    detailsContainer.style.alignItems = 'center';
    detailsContainer.style.gap = '5px';
    detailsContainer.innerHTML = `
        <p style="color: #0f0f0f; font-weight: bold; margin: 0;">${video.views} views</p>
        <p style="color: #0f0f0f; font-weight: bold; margin: 0;">Published: ${formattedDate}</p>
    `;
    descriptionContainer.appendChild(detailsContainer);

    // Add video description with links and hashtags
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    var hashtagRegex = /#(\w+)/g;
    var youtubeLinkRegex = /https:\/\/www\.youtube\.com\/watch/i;
   
    video.description.split('\n').forEach(function (line) {
        if (urlRegex.test(line)) {
            var formattedLine = line.replace(urlRegex, function (url) {
                if (youtubeLinkRegex.test(url)) {
                    return `<span style="background-color: rgba(0,0,0,0.051); border-radius: 8px; padding: 2px 8px; font-size: 14px; color: #131313; line-height: 20px; margin-bottom: 4px; display: inline-flex; align-items: center;">
                                <img src="https://www.gstatic.com/youtube/img/watch/yt_favicon.png" style="width: 14px; height: 10px; margin-right: 4px;">
                                <a href="${url}" target="_blank" style="text-decoration: none; color: #131313;">${url}</a>
                            </span><br>`;
                } else {
                    return `<a href="${url}" target="_blank" style="text-decoration:none">${url}</a><br>`;
                }
            });
            descriptionContainer.innerHTML += formattedLine;
        } else {
            var paragraph = document.createElement('p');
            paragraph.textContent = line;

            paragraph.innerHTML = paragraph.innerHTML.replace(hashtagRegex, function (match) {
                return `<a href="https://www.youtube.com/hashtag/${encodeURIComponent(match.substring(1))}" target="_blank" style="color: #065fd4;">${match}</a>`;
            });

            descriptionContainer.appendChild(paragraph);
        }
    });
    
    // Add event listener to close the modal when clicking outside the content
    window.addEventListener('click', function (event) {
        if (event.target === modal) {
            closeModal();
        }
    });
}

function closeModal() {
    const modal = document.getElementById('video-modal');
    if (modal) {
        modal.style.display = 'none';
    }
}
